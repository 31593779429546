import React, { useState } from "react"
import { Link } from "gatsby"
import { Grid, Paper } from "@material-ui/core"
import { navigate } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"

import Axios from "axios"

import host from "../../config/index"

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const _auth = () => {
    Axios.post(`${host}/auth/login/admin`, {
      username: username,
      password: password,
    }).then(({ data }) => {
      localStorage.setItem("token", data.data.token)
      localStorage.setItem("username", data.data.user.username)
      localStorage.setItem("role", data.data.user.role)
      localStorage.setItem("user", JSON.stringify(data.data.user))
      navigate("/admin/clients")
    })
  }

  return (
    <div style={{ padding: 20 }}>
      <Grid align="center">
        <img src={require("../../../assets/Logo/logo.png")} width={200} />
        <Paper
          style={{ padding: 20, height: 400, maxWidth: 400, marginTop: 30 }}
        >
          <p style={{ fontSize: 20 }}>
            <b>Sign in</b>
          </p>
          <form>
            <div class="form-group" style={{ textAlign: "left" }}>
              <span for="exampleInputEmail1">username</span>
              <br />
              <input
                style={{
                  width: "100%",
                  height: 30,
                  marginTop: 10,
                  marginBottom: 20,
                }}
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                value={username}
                onChange={e => setUsername(e.target.value)}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group" style={{ textAlign: "left" }}>
              <span for="exampleInputPassword1">Password</span>
              <br />
              <input
                style={{ width: "100%", height: 30, marginTop: 10 }}
                type="password"
                class="form-control"
                id="exampleInputPassword1"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="row" style={{ padding: 20 }}>
              {/* <Link to={"/home"}> */}
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  backgroundColor: "#E51C21",
                  borderColor: "#E51C21",
                  borderRadius: 20,
                  width: 200,
                  color: "#FFF",
                  height: 40,
                }}
                onClick={e => {
                  e.preventDefault()
                  _auth()
                }}
              >
                Sign in
              </button>
              {/* </Link> */}

              <a style={{ marginLeft: 20, paddingTop: 8 }} href="">
                forgot password ?
              </a>
            </div>
          </form>
          <p>
            By signing in, you agree to our <a href="">terms of use</a>
          </p>
        </Paper>
      </Grid>
    </div>
  )
}
